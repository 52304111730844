import { ToastProgrammatic as Toast } from 'buefy';
import { copyTextToClipboard } from '@/util/clipboard';
import { useI18n } from '@/devicehub/utils/i18n';

type IdSelectorFunction<T> = (obj: T) => string;

export function useCopyId<T>(idSelectorFunction: IdSelectorFunction<T>): {
  onCopyId: (obj: T) => Promise<void>;
} {
  const i18n = useI18n();

  const onCopyId = async (obj: T): Promise<void> => {
    const result = await copyTextToClipboard(idSelectorFunction(obj));
    if (result) {
      Toast.open({
        message: i18n.tc('common.copySuccess'),
        type: 'is-success',
      });
    } else {
      Toast.open({
        message: i18n.tc('common.copyError'),
        type: 'is-danger',
      });
    }
  };

  return { onCopyId };
}
