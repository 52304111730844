




























































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useParticipantStore } from '@/devicehub/apps/dhStudy/stores/participantStore';
import { useTabs } from '@/devicehub/utils/tabs';
import DeviceAssignmentList from './DeviceAssignmentList.vue';
import DeviceAssignmentForm from './DeviceAssignmentForm.vue';
import { confirmRouteLeave } from '@/devicehub/utils/router';

export default defineComponent({
  components: { DeviceAssignmentList, DeviceAssignmentForm },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  beforeRouteLeave(to, fromWhere, next) {
    confirmRouteLeave(next, this.isDirty);
  },
  setup(props) {
    const participantStore = useParticipantStore();

    const { activeTab, setTab } = useTabs(2);

    const mode = ref<'list' | 'form'>('list');

    const isDirty = ref<boolean>(false);

    onMounted(async () => {
      await participantStore.getParticipantById(props.id);
    });

    const onChangeMode = (newMode: 'list' | 'form') => {
      mode.value = newMode;
      isDirty.value = false;
    };

    const getDashboardUrl = (): string => {
      return `${participantStore.participantDashboardUrl}${props.id}`;
    };

    return {
      participantStore,
      getDashboardUrl,
      activeTab,
      setTab,
      mode,
      onChangeMode,
      isDirty,
    };
  },
});
