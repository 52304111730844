import { getCurrentInstance } from '@vue/composition-api';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';
import { DialogProgrammatic as Dialog } from 'buefy';
import { useI18n } from './i18n';

export function useRoute(): Route {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');

  return vm.proxy.$route;
}

export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) throw new Error('must be called in setup');

  return vm.proxy.$router;
}

export function confirmRouteLeave(
  next: NavigationGuardNext,
  shouldConfirm = true,
): void {
  if (shouldConfirm) {
    const i18n = useI18n();
    try {
      Dialog.confirm({
        message: i18n.tc('common.leaveWithoutSaveQuestion'),
        onConfirm: () => next(),
        onCancel: () => next(false),
      });
    } catch (error) {
      console.error(error);
      next();
    }
  } else {
    next();
  }
}
