





























import { Vue, Component, Prop } from 'vue-property-decorator';

import { Participant } from '@/models/study/models';

@Component({
  components: {},
})
export default class ParticipantStateTransition extends Vue {
  @Prop({ required: true }) id: string;

  @Prop({ required: true }) participant: Participant;

  @Prop({ required: true }) options: { states: string[] };

  async transition(state: string): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await Participant.updateState(this.id, state);
      this.$emit('state-change');
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
