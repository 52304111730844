import { DeviceDataEvent } from '@/models/device/interfaces';
import { ParticipantEventQuery, Participant } from '@/models/study/models';
import { Dictionary } from '@/util/interfaces';
import { isSameDay } from 'date-fns';
import {
  WellBeingData,
  StreamIdentity,
  NutritionData,
  ActivityStrengthData,
  ActivityEnduranceData,
  HelpData,
} from './interfaces';
import { eulachStore } from './store';

export function isMonitoringDay(date: Date): boolean {
  for (const day of eulachStore.monitoringDays) {
    if (isSameDay(date, new Date(day))) {
      return true;
    }
  }
  return false;
}

async function getEventsOfDays(
  participantId: string,
  days: Date[],
  identity: string,
): Promise<Map<string, DeviceDataEvent[]>> {
  const query: ParticipantEventQuery = {
    patient: participantId,
    page: 1,
    page_size: 500,
    start_time: eulachStore.startDate,
    end_time: new Date().toISOString(),
    identity: identity,
  };
  const events = await Participant.queryEvents(query);
  const eventMap = new Map<string, DeviceDataEvent[]>();
  for (const day of days) {
    const eventsOfDay = [];
    for (const event of events.results) {
      if (isSameDay(day, new Date(event.time))) {
        eventsOfDay.push(event);
      }
    }
    eventMap.set(day.toISOString(), eventsOfDay.reverse());
  }
  return eventMap;
}

async function getEvents(
  participantId: string,
  identity: string,
): Promise<DeviceDataEvent[]> {
  const query: ParticipantEventQuery = {
    patient: participantId,
    page: 1,
    page_size: 500,
    start_time: eulachStore.startDate,
    end_time: new Date().toISOString(),
    identity: identity,
  };
  const events = await Participant.queryEvents(query);
  return events.results;
}

export async function getWellBeing(
  participantId: string,
  days: Date[],
): Promise<WellBeingData[]> {
  const eventMap = await getEventsOfDays(
    participantId,
    days,
    StreamIdentity.WELL_BEING,
  );
  const symptomsEventMap = await getEventsOfDays(
    participantId,
    days,
    StreamIdentity.SYMPTOMS,
  );
  const data: WellBeingData[] = [];
  for (const day of days) {
    const eventsOfDay = eventMap.get(day.toISOString());
    if (eventsOfDay === undefined) {
      throw new Error('No events found.');
    }
    let wellBeing: number | undefined;
    const symptoms: Dictionary<number> = {};
    for (const event of eventsOfDay) {
      if (event?.payload?.data?.columns?.[0] === 'feeling') {
        wellBeing = event.payload?.data?.data?.[0]?.[0] as number;
      }
      const numberOfColumns = event?.payload?.data?.columns?.length;
      if (numberOfColumns !== undefined && numberOfColumns > 0) {
        event.payload.data?.columns.forEach((symptom, index) => {
          symptoms[symptom] = event.payload?.data?.data?.[0]?.[index] as number;
        });
      }
    }
    const symptomEventsOfDay = symptomsEventMap.get(day.toISOString());
    if (symptomEventsOfDay) {
      for (const event of symptomEventsOfDay) {
        const numberOfColumns = event?.payload?.data?.columns?.length;
        if (numberOfColumns !== undefined && numberOfColumns > 0) {
          event.payload.data?.columns.forEach((symptom, index) => {
            symptoms[symptom] = event.payload?.data?.data?.[0]?.[
              index
            ] as number;
          });
        }
      }
    }
    data.push({
      date: day,
      isMonitoringDay: isMonitoringDay(day),
      wellBeing: wellBeing,
      symptoms: symptoms,
    });
  }
  return data;
}

export async function getNutritionData(
  participantId: string,
  days: Date[],
): Promise<NutritionData[]> {
  const eventMap = await getEventsOfDays(
    participantId,
    days,
    StreamIdentity.WEIGHT,
  );
  const data: NutritionData[] = [];
  for (const day of days) {
    const eventsOfDay = eventMap.get(day.toISOString());
    if (eventsOfDay === undefined) {
      throw new Error('No events found.');
    }
    let weight: number | undefined;
    for (const event of eventsOfDay) {
      if (event?.payload?.data?.columns?.[0] === 'weight') {
        weight = event.payload?.data?.data?.[0]?.[0] as number;
      }
    }
    // only add row if there is data or it is a monitoring day -> data is expected
    if (weight || isMonitoringDay(day)) {
      data.push({
        date: day,
        isMonitoringDay: isMonitoringDay(day),
        weight,
      });
    }
  }
  return data;
}

export async function getActivityEndurance(
  participantId: string,
): Promise<ActivityEnduranceData[]> {
  const events = await getEvents(
    participantId,
    StreamIdentity.ACTIVITY_ENDURANCE,
  );
  const data: ActivityEnduranceData[] = [];
  for (const event of events) {
    const eventData: Dictionary<number> = {};
    const keys = ['category', 'activity', 'duration', 'intensity'];
    keys.forEach(key => {
      const index = event?.payload?.data?.columns?.findIndex(
        col => col === key,
      );
      if (index !== undefined && index !== -1) {
        eventData[key] = event.payload?.data?.data?.[0]?.[index] as number;
      }
    });
    // only add row if there is data
    if (Object.keys(eventData).length > 0) {
      data.push({
        date: new Date(event.time),
        ...eventData,
      });
    }
  }
  return data;
}

export async function getActivityStrength(
  participantId: string,
): Promise<[ActivityStrengthData[], number]> {
  let session = 1;
  const events = await getEvents(
    participantId,
    StreamIdentity.ACTIVITY_STENGTH,
  );
  const strengthData: ActivityStrengthData[] = [];
  for (const event of events) {
    const keys = [
      'exercise',
      'weight',
      'sets',
      'repetitions',
      'duration',
      'selected_theraband',
    ];
    const indices: Dictionary<number> = {};
    keys.forEach(key => {
      const index = event?.payload?.data?.columns?.findIndex(
        col => col === key,
      );
      if (index !== undefined && index !== -1) {
        indices[key] = index;
      }
    });
    event.payload.data?.data.forEach(data => {
      const eventData: Dictionary<number> = {};
      Object.keys(indices).forEach(key => {
        eventData[key] = data[indices[key]] as number;
      });
      // only add row if there is data
      if (Object.keys(eventData).length > 0) {
        strengthData.push({
          date: new Date(event.time),
          session,
          ...eventData,
        });
      }
    });
    session += 1;
  }
  return [strengthData, session];
}

export async function getHelpData(participantId: string): Promise<HelpData[]> {
  const events = await getEvents(participantId, StreamIdentity.HELP_MESSAGE);
  const data: HelpData[] = [];
  for (const event of events) {
    if ((event.payload.data as any)?.subject) {
      data.push({
        date: new Date(event.time),
        subject: (event.payload.data as any)?.subject,
        body: (event.payload.data as any)?.body,
      });
    }
  }
  return data;
}

/**
 * For participants in the control arm, we don't show the actual data but just an indication that data is there
 */
export function filterValueIfControl(
  value?: number | string,
): number | string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (eulachStore.participantIsInControlArm) {
    return '✅';
  }
  return value;
}
