







































































































import { Vue, Component, Prop } from 'vue-property-decorator';

import { Participant } from '@/models/study/models';
import { ModelField } from '@/models/core/base';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';
import { Profile } from '@/models/core/profile';
import {
  createObjectsForParticipant,
  revokeParticipantObjects,
} from '../actions';
import QrcodeVue from 'qrcode.vue';
import {
  ParticipantMeta,
  ParticipantState,
  QRCodeValue,
  StreamIdentity,
} from '../interfaces';
import { globalStore } from '@/store/modules/global';

@Component({ components: { QrcodeVue } })
export default class ParticipantSettings extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: false }) options?: { fields?: ModelField[] };
  @Prop({ required: true }) participant!: Participant<ParticipantMeta>;

  hasRegistrationEvent = false;
  interval = 0;
  showJson = false;
  tokenKey = '';

  Participant = Participant;

  get fields(): ModelField[] {
    return [
      {
        key: 'pid',
        formProperties: {
          editable: false,
          class: 'width-400',
        },
      },
      {
        key: 'meta.snaqUsername',
        customLabel: 'SNAQ Username',
        placeholder: 'SNAQ Username',
        formProperties: {
          editable: true,
          class: 'width-400',
        },
        required: false,
      },
      {
        formFieldType: FormFieldType.SELECTION,
        formProperties: {
          editable: !this.hasToken,
          options: [
            {
              text: 'Control',
              value: 'control',
            },
            {
              text: 'Intervention',
              value: 'intervention',
            },
          ],
          class: 'width-400',
        },
        customLabel: 'Studienarm',
        placeholder: 'Studienarm',
        key: 'meta.studyArm',
      },
      {
        formFieldType: FormFieldType.DATE_FIELD,
        formProperties: {
          editable: !this.hasToken,
          class: 'width-400',
        },
        customLabel: 'Monitoring Start',
        placeholder: 'Monitoring Start',
        key: 'meta.startDate',
      },
      {
        formFieldType: FormFieldType.RELATED_MODEL_SELECT,
        formProperties: {
          relatedObjectProperty: 'email',
          modelClass: Profile.objectType,
          class: 'width-400',
          filters: {
            organisation: globalStore.selection?.organisation?.id,
            kind: 'human',
          },
        },
        customLabel: 'Verantwortliche Person',
        placeholder: 'Verantwortliche Person',
        key: 'meta.responsiblePerson',
      },
    ];
  }

  get showParticipantForm(): boolean {
    return (
      this.participant.state === 'registered' ||
      this.participant.state === 'active'
    );
  }

  get hasToken(): boolean {
    return !!this.participant.meta?.participantObjects?.tokenId;
  }

  get enableCreateQrCodeButton(): boolean {
    return (
      !!this.participant.meta?.studyArm &&
      !!this.participant.meta?.responsiblePerson
    );
  }

  get qrCodeValue(): string {
    if (this.participant.meta?.studyArm === undefined) {
      throw new Error('Invalid study arm');
    }
    if (this.participant.meta?.participantObjects?.device === undefined) {
      throw new Error('No app registered for this participant');
    }
    const value: QRCodeValue = {
      url: `${window.location.protocol}//${window.location.host}/`,
      token: this.tokenKey,
      device: this.participant.meta?.participantObjects?.device,
      session_config:
        globalStore.clientAppSetting('eulach_config')?.value?.session_config,
      patient: this.participant.id,
      patient_pid: this.participant.pid,
      study_arm: this.participant.meta?.studyArm,
    };
    return JSON.stringify(value);
  }

  get canActivate(): boolean {
    return this.hasToken;
  }

  get deviceId(): string {
    return this.participant.meta?.participantObjects?.device ?? '';
  }

  mounted(): void {
    this.interval = setInterval(async () => {
      await this.getRegistrationEvent();
    }, 3000);
  }

  destroyed(): void {
    clearInterval(this.interval);
  }

  async getRegistrationEvent(): Promise<void> {
    try {
      if (this.hasToken && !this.hasRegistrationEvent) {
        console.log('getRegistrationEvent');
        const events = await Participant.queryEvents({
          page: 1,
          page_size: 1,
          role: `app-${this.participant.meta?.studyArm}`,
          patient: this.participant.id,
          identity: StreamIdentity.REGISTRATION_MESSAGE,
        });
        if (events.results.length > 0) {
          if (
            events.results[0].device ===
            this.participant.meta?.participantObjects?.device
          ) {
            this.hasRegistrationEvent = true;
          }
        }
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  onUpdate(participant: Participant): void {
    this.participant.meta = participant.meta;
  }

  async prepareQrCode(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const [participantObjects, tokenKey] = await createObjectsForParticipant(
        this.participant,
      );
      if (this.participant.meta === undefined) {
        this.participant.meta = {};
      }
      this.participant.meta.participantObjects = participantObjects;
      this.tokenKey = tokenKey;
      const participant = await this.$apiv2.update<
        Partial<Participant>,
        Participant
      >(Participant, {
        id: this.participant.id,
        meta: this.participant.meta,
      });
      this.onUpdate(participant);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async revoke(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      if (this.participant.meta?.participantObjects !== undefined) {
        await revokeParticipantObjects(
          this.participant.meta.participantObjects,
        );
        this.participant.meta.participantObjects = undefined;
        await this.$apiv2.update<Partial<Participant>, Participant>(
          Participant,
          {
            id: this.participant.id,
            meta: this.participant.meta,
          },
        );
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async transition(state: ParticipantState): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await Participant.updateState(this.id, state);
      this.$emit('state-change');
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async activate(): Promise<void> {
    await this.transition('active');
  }

  async complete(): Promise<void> {
    this.$buefy.dialog.confirm({
      message:
        'Sind Sie sicher, dass Sie das Monitoring beenden wollen? Der App Token wird gesperrt und ein neuer QR Code muss gescannt werden, um das Monitoring fortzusetzen. ',
      onConfirm: async () => {
        await this.revoke();
        await this.transition('completed');
      },
    });
  }

  async reregister(): Promise<void> {
    this.$buefy.dialog.confirm({
      message:
        'Sind Sie sicher, dass Sie diesen Teilnehmer neu registrieren wollen?',
      onConfirm: async () => {
        await this.transition('registered');
      },
    });
  }
}
