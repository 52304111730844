export type ParticipantState = 'registered' | 'active' | 'completed';

export type StudyArm = 'control' | 'intervention';

export enum StreamIdentity {
  REGISTRATION_MESSAGE = 'registration-message',
  WELL_BEING = 'well-being',
  SYMPTOMS = 'symptoms',
  WEIGHT = 'weight',
  ACTIVITY_STENGTH = 'activity-strength',
  ACTIVITY_ENDURANCE = 'activity-endurance',
  HELP_MESSAGE = 'help-message',
}

export interface EulachConfig {
  profile: string;
  session_config: string;
  models: {
    control: string;
    intervention: string;
  };
}

export interface EulachDeviceSetting {
  key: string;
  value: {
    [key: string]: any;
  };
}

export interface EulachDeviceSettings {
  device_settings: EulachDeviceSetting[];
}

export interface ParticipantObjects {
  device: string;
  deviceRelation: string;
  deviceAssignment: string;
  tokenId: string;
}

export interface ParticipantMeta {
  studyArm?: StudyArm;
  responsiblePerson?: string;
  participantObjects?: ParticipantObjects;
  startDate?: string;
  snaqUsername?: string;
}

export interface QRCodeValue {
  url: string;
  token: string;
  device: string;
  session_config: string;
  patient: string;
  patient_pid: string;
  study_arm: StudyArm;
}

export interface Symptoms {
  [key: string]: number;
}

export interface WellBeingData {
  date: Date;
  isMonitoringDay: boolean;
  wellBeing?: number;
  symptoms: Symptoms;
}

export interface NutritionData {
  date: Date;
  isMonitoringDay: boolean;
  weight?: number;
}

export interface ActivityEnduranceData {
  date: Date;
  category?: string;
  activity?: string;
  duration?: number;
  intensity?: number;
}

export interface ActivityStrengthData {
  date: Date;
  session: number;
  exercise?: string;
  weight?: number;
  sets?: number;
  repetitions?: number;
  duration?: number;
  selected_theraband?: string;
}

export interface HelpData {
  date: Date;
  subject: string;
  body: string;
}

export interface SnaqAuthenticationResponse {
  token: string;
  url: string;
  language?: string;
  validFor?: {
    unit?: string;
    value?: string | number;
  };
}
