























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant } from '@/models/study/models';
import { HelpData, ParticipantMeta } from '../interfaces';
import { eulachStore } from '../store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getHelpData } from '../dataHelpers';

@Component({ components: {} })
export default class HelpMessages extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) participant!: Participant<ParticipantMeta>;

  data: HelpData[] = [];
  destroySubject = new Subject<void>();

  async mounted(): Promise<void> {
    try {
      await eulachStore.initialize(this.participant);
      await this.loadData();
      this.$apiv2
        .getRefreshStream()
        .pipe(takeUntil(this.destroySubject))
        .subscribe(() => {
          this.loadData();
        });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async loadData(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      this.data = await getHelpData(this.participant.id);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
