




































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { debounce } from 'lodash';
import { useDeviceAssignmentStore } from '@/devicehub/apps/dhStudy/stores/deviceAssignmentStore';
import { DeviceDetail } from '@/generated/api/study/api';
import PerPage from '@/devicehub/components/PerPage.vue';
import BatteryDisplay from '@/apps/monitoring/components/BatteryDisplay.vue';
import { useI18n } from '@/devicehub/utils/i18n';

export default defineComponent({
  components: { PerPage, BatteryDisplay },
  props: {
    deviceUuid: {
      type: String,
      required: false,
    },
    startTime: {
      type: Date,
      required: false,
    },
    endTime: {
      type: Date,
      required: false,
    },
  },
  setup(props, { emit }) {
    const deviceAssignmentStore = useDeviceAssignmentStore();
    const i18n = useI18n();
    const pageSize = ref(10);
    const { startTime, endTime } = toRefs(props);

    const updateAvailableDevices = debounce(async () => {
      if (startTime?.value) {
        await deviceAssignmentStore.getAvailableDevices(
          startTime.value.toISOString(),
          endTime?.value?.toISOString(),
          deviceAssignmentStore.currentDeviceAssignment?.assignment.id,
        );
      }
    }, 300);

    onMounted(updateAvailableDevices);

    watch(() => startTime?.value, updateAvailableDevices);
    watch(() => endTime?.value, updateAvailableDevices);

    const onSelect = (deviceDetail: DeviceDetail) => {
      emit('device-selected', deviceDetail.device);
    };

    const footer = computed((): string => {
      const total = deviceAssignmentStore.filteredAvailableDevices.length;
      return `${total} ${i18n.tc('dhStudy.availableDevice.this', total)}`;
    });

    return {
      deviceAssignmentStore,
      pageSize,
      onSelect,
      footer,
    };
  },
});
