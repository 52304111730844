























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant } from '@/models/study/models';
import { ParticipantMeta, WellBeingData } from '../interfaces';
import { eulachStore } from '../store';
import { eachDayOfInterval } from 'date-fns';
import { filterValueIfControl, getWellBeing } from '../dataHelpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({ components: {}, filters: { filterValueIfControl } })
export default class WellBeing extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) participant!: Participant<ParticipantMeta>;
  data: WellBeingData[] = [];
  destroySubject = new Subject<void>();

  get symptoms(): string[] {
    return eulachStore.symptoms;
  }

  async mounted(): Promise<void> {
    try {
      await eulachStore.initialize(this.participant);
      await this.loadData();
      this.$apiv2
        .getRefreshStream()
        .pipe(takeUntil(this.destroySubject))
        .subscribe(() => {
          this.loadData();
        });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async loadData(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const days = eachDayOfInterval({
        start: new Date(eulachStore.startDate),
        end: new Date(),
      }).reverse();
      this.data = await getWellBeing(this.participant.id, days);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  rowClass(row: WellBeingData): string {
    if (row.isMonitoringDay) {
      return 'monitoring-day';
    } else {
      return '';
    }
  }
}
