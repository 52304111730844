





























































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { DialogProgrammatic as Dialog } from 'buefy';
import { useDeviceAssignmentStore } from '@/devicehub/apps/dhStudy/stores/deviceAssignmentStore';
import { AssignmentDetail } from '@/generated/api/study';
import { useCopyId } from '@/devicehub/utils/actions';
import PerPage from '@/devicehub/components/PerPage.vue';
import { useI18n } from '@/devicehub/utils/i18n';

export default defineComponent({
  components: { PerPage },
  props: {
    participantId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deviceAssignmentStore = useDeviceAssignmentStore();
    const i18n = useI18n();
    const pageSize = ref(10);
    const { onCopyId } = useCopyId(
      (a: AssignmentDetail) => a.assignment.id ?? '',
    );

    onMounted(
      async () =>
        await deviceAssignmentStore.getDeviceAssignmentsOfParticipant(
          props.participantId,
        ),
    );

    const onCreateNew = (): void => {
      deviceAssignmentStore.currentDeviceAssignment = null;
      emit('change-mode', 'form');
    };

    const onRowClick = (assignment: AssignmentDetail): void => {
      deviceAssignmentStore.currentDeviceAssignment = assignment;
      emit('change-mode', 'form');
    };

    const onEndNow = async (assignment: AssignmentDetail): Promise<void> => {
      Dialog.confirm({
        message: i18n.tc('dhStudy.deviceAssignment.confirmEndNow'),
        onConfirm: async () => {
          await deviceAssignmentStore.endDeviceAssignmentNow(assignment);
          await deviceAssignmentStore.getDeviceAssignmentsOfParticipant(
            props.participantId,
          );
        },
      });
    };

    const footer = computed((): string => {
      const total = deviceAssignmentStore.deviceAssignmentsOfParticipant.length;
      return `${total} ${i18n.tc('dhStudy.deviceAssignment.this', total)}`;
    });

    return {
      deviceAssignmentStore,
      onCreateNew,
      onRowClick,
      onCopyId,
      onEndNow,
      pageSize,
      footer,
    };
  },
});
