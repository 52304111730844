import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { apiClientV2 } from '@/api/ApiClientV2';
import { Participant } from '@/models/study/models';
import { ParticipantMeta, StreamIdentity } from './interfaces';
import { Device } from '@/models/device/models';
import { DeviceEventType } from '@/models/device/interfaces';
import { startOfDay } from 'date-fns';

export interface EulachState {
  participant: Participant<ParticipantMeta> | null;
  startDate: string;
  symptoms: string[];
  monitoringDays: string[];
}

@Module({ dynamic: true, store: store, name: 'eulach', namespaced: true })
class Eulach extends VuexModule implements EulachState {
  participant: Participant<ParticipantMeta> | null = null;
  startDate = '';
  symptoms: string[] = [];
  monitoringDays: string[] = [];

  get participantIsInControlArm(): boolean {
    return this.participant?.meta?.studyArm === 'control';
  }

  @Mutation
  setParticipant(participant: Participant<ParticipantMeta>) {
    this.participant = participant;
  }

  @Mutation
  setStartDate(startDate: string) {
    this.startDate = startDate;
  }

  @Mutation
  setSymptoms(symptoms: string[]) {
    this.symptoms = symptoms;
  }

  @Mutation
  setMonitoringDays(monitoringDays: string[]) {
    this.monitoringDays = monitoringDays;
  }

  @Action
  async initialize(participant: Participant<ParticipantMeta>) {
    if (this.participant?.id !== participant.id) {
      this.setParticipant(participant);
      if (this.participant?.meta?.participantObjects?.device === undefined) {
        throw new Error('No app registered for this participant.');
      }
      const result = await Participant.queryEvents({
        patient: this.participant.id,
        page: 1,
        page_size: 1,
        // take oldest registration message as start
        order_by: 'time_asc',
        event_type: DeviceEventType.DEVICE_DATA,
        identity: StreamIdentity.REGISTRATION_MESSAGE,
      });
      if (result.results?.[0]?.time) {
        // use startOfDay as timestamp of well being events is also startOfDay
        const startDate = startOfDay(
          new Date(result.results[0].time),
        ).toISOString();
        this.setStartDate(startDate);
      } else {
        throw new Error('No registration message found.');
      }

      const symptomsSetting = await Device.getSetting(
        apiClientV2,
        this.participant.meta.participantObjects.device,
        'symptoms',
      );
      if (symptomsSetting?.value?.symptoms?.length > 0) {
        this.setSymptoms(symptomsSetting.value.symptoms);
      } else {
        throw new Error('No symptoms found in device settings.');
      }

      const monitoringDaysSetting = await Device.getSetting(
        apiClientV2,
        this.participant.meta.participantObjects.device,
        'monitoring_days',
      );
      if (monitoringDaysSetting?.value?.monitoring_days?.length > 0) {
        this.setMonitoringDays(monitoringDaysSetting.value.monitoring_days);
      } else {
        throw new Error('No monitoring days found in device settings.');
      }
    }
  }
}

export const eulachStore = getModule(Eulach);
