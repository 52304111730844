




















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant } from '@/models/study/models';
import { ParticipantMeta, SnaqAuthenticationResponse } from '../interfaces';
import { embedSNAQ } from '../embedSNAQ';

@Component({ components: {} })
export default class ActivityTable extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) participant!: Participant<ParticipantMeta>;

  async loadSnaq(): Promise<void> {
    try {
      if (
        this.participant.meta?.snaqUsername === undefined ||
        this.participant.meta?.snaqUsername === ''
      ) {
        throw new Error(
          'Keinen SNAQ Usernamen gefunden. Siehe Teilnehmer Einstellungen.',
        );
      }
      const snaqAuthenticationResponse: SnaqAuthenticationResponse =
        await this.$apiv2.customPost('ksw/snaq/authenticate', {
          snaq_user: this.participant.meta.snaqUsername,
        });
      embedSNAQ({
        id: 'snaq',
        token: snaqAuthenticationResponse.token,
        url: snaqAuthenticationResponse.url,
        language: snaqAuthenticationResponse.language ?? 'de_DE',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }
}
