



































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant } from '@/models/study/models';
import {
  ActivityEnduranceData,
  ActivityStrengthData,
  ParticipantMeta,
} from '../interfaces';
import { eulachStore } from '../store';
import {
  filterValueIfControl,
  getActivityEndurance,
  getActivityStrength,
} from '../dataHelpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({ components: {}, filters: { filterValueIfControl } })
export default class ActivityTable extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) participant!: Participant<ParticipantMeta>;

  maxSession = 0;
  strengthData: ActivityStrengthData[] = [];
  enduranceData: ActivityEnduranceData[] = [];

  destroySubject = new Subject<void>();

  async mounted(): Promise<void> {
    try {
      await eulachStore.initialize(this.participant);
      await this.loadData();
      this.$apiv2
        .getRefreshStream()
        .pipe(takeUntil(this.destroySubject))
        .subscribe(() => {
          this.loadData();
        });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async loadData(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      [this.strengthData, this.maxSession] = await getActivityStrength(
        this.participant.id,
      );
      this.enduranceData = await getActivityEndurance(this.participant.id);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  formatDuration(seconds: number | undefined): string {
    if (typeof seconds === 'number') {
      return (seconds / 60).toFixed(0);
    } else {
      return '';
    }
  }
}
