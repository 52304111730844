






























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant, ParticipantEventQuery } from '@/models/study/models';
import AutoRefresh from '@/apps/device/components/AutoRefresh.vue';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { copyTextToClipboard } from '@/util/clipboard';
import { deepCopy } from '@/util/util';

@Component({
  components: {
    AutoRefresh,
  },
})
export default class ParticipantHistory extends Vue {
  @Prop({ required: true }) id: string;
  @Prop({ required: true }) participant: Participant;

  pagination = { page: 1, pageSize: 10 };
  startDate = null;
  endDate = null;
  results = [];
  loading = true;
  destroySubject = new Subject<void>();

  get hasPagination(): boolean {
    return this.total > this.pagination.pageSize ? true : false;
  }

  get total(): number {
    if (this.results.length === this.pagination.pageSize) {
      return (this.pagination.page + 1) * this.results.length;
    } else {
      return (
        (this.pagination.page - 1) * this.pagination.pageSize +
        this.results.length
      );
    }
  }

  mounted(): void {
    this.getEventResults();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getEventResults();
      });
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onPageChange(page: number): void {
    this.pagination.page = page;
    this.getEventResults();
  }

  onStart(date: Date): void {
    this.startDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  onEnd(date: Date): void {
    this.endDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  async getEventResults(showLoading = true): Promise<void> {
    if (showLoading) this.loading = true;
    const query: ParticipantEventQuery = {
      patient: this.id,
      page: this.pagination.page,
      page_size: this.pagination.pageSize,
      start_time: this.startDate ? this.startDate.toISOString() : '',
      end_time: this.endDate ? this.endDate.toISOString() : '',
    };
    try {
      const events = await Participant.queryEvents(query);
      events.results.forEach((r, index) => {
        r['index'] = index;
      });
      this.results = events.results;
    } catch (error) {
      this.$errorHandler.handleError(error);
      this.results = [];
    }
    this.loading = false;
  }

  copyEventData(row: any): void {
    const copy = deepCopy(row);
    delete copy.index;
    copyTextToClipboard(JSON.stringify(copy, null, 2));
    this.$buefy.toast.open({
      message: this.$tc('common.copiedEvent'),
      type: 'is-success',
    });
  }
}
