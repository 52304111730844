













import { Vue, Component, Prop } from 'vue-property-decorator';

import { Participant } from '@/models/study/models';
import { ModelField } from '@/models/core/base';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';

@Component({})
export default class ParticipantBaseForm extends Vue {
  @Prop({ required: true }) id: string;
  @Prop({ required: false }) options: { fields?: ModelField[] };

  Participant = Participant;

  get fields(): ModelField[] {
    if (this.options?.fields?.length > 0) {
      return this.options.fields;
    } else {
      return [
        {
          key: 'pid',
          formProperties: {
            editable: false,
            class: 'width-400',
          },
        },
        {
          key: 'state',
          formProperties: {
            class: 'width-400',
          },
          required: false,
        },
        {
          key: 'meta',
          formFieldType: FormFieldType.JSON_FIELD,
          required: false,
          undotized: true,
        },
      ];
    }
  }
}
