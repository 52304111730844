








































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Participant, UpdateParticipantLabel } from '@/models/study/models';
import { deepCopy } from '@/util/util';
import { UPDATE_PARTICIPANT_LABEL_DEFAULT } from '@/models/study/defaults';
import { DeviceRelation } from '@/models/data/models';
import { BaseListColumn, ListModelField } from '@/models/core/base';
import ConfigSelector from '@/apps/data/components/ConfigSelector.vue';
import { BackgroundTask } from '@/models/core/models';
import { globalStore } from '@/store/modules/global';
import { DeviceEventLog, Device } from '@/models/device/models';
import { Filter } from '@/api/ApiClientV2';

@Component({
  components: {
    ConfigSelector,
  },
})
/**
 * Assign/unassign data of a specifig device to/from a participant.
 */
export default class ParticipantBaseForm extends Vue {
  // participant ID
  @Prop({ required: true }) id: string;

  start = new Date();
  end = new Date();

  DeviceRelation = DeviceRelation;

  // set role to null to show placeholder of select field
  updateLabel: UpdateParticipantLabel = {
    ...deepCopy(UPDATE_PARTICIPANT_LABEL_DEFAULT),
    role: null,
  };

  get dataClientApp(): string {
    return globalStore.clientAppSetting('data_application')?.value
      ?.data_client_app_id;
  }

  get filter(): Filter {
    return {
      application: globalStore.selection.application?.id ?? 'unknown',
      order_by: 'device_name_asc',
    };
  }

  get columns(): BaseListColumn[] {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
      },
      {
        key: 'device_device_id',
      },
      {
        key: 'role',
      },
      {
        key: 'assigned_pid',
      },
      {
        key: 'assigned_role',
      },
    ];
    return DeviceRelation.defaultColumns(DeviceRelation.langPath, listFields);
  }

  get canSave(): boolean {
    if (this.updateLabel.assign) {
      return !!this.updateLabel.role && !!this.updateLabel.device;
    } else {
      return !!this.updateLabel.device;
    }
  }

  get roleOptions(): { role: string; template: string }[] {
    return (
      globalStore.clientAppSetting('config')?.value?.device_assignment_roles ||
      []
    );
  }

  onSelect(selection: DeviceRelation[] | undefined): void {
    this.updateLabel.device = selection?.[0]?.device;
  }

  async save(): Promise<void> {
    try {
      this.updateLabel.start_time = this.start.toISOString();
      this.updateLabel.end_time = this.end.toISOString();

      if (this.updateLabel.assign) {
        // assigning new data
        await this.newAssignment();
      } else {
        // unassigning data

        // check that there is actually some data assigned
        const result = await Participant.queryEvents({
          page: 1,
          page_size: 1,
          start_time: this.updateLabel.start_time,
          end_time: this.updateLabel.end_time,
          role: this.updateLabel.role,
          patient: this.id,
        });
        if (result.results.length > 0) {
          // there is some data
          this.$buefy.dialog.confirm({
            message: this.$tc('study.dataassignment.confirmUnassign'),
            onConfirm: async () => {
              await this.runAssignment(this.id, this.updateLabel);
            },
          });
        } else {
          this.$buefy.dialog.alert({
            message: this.$tc('study.dataassignment.noDataAssigned'),
          });
        }
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
  }

  async newAssignment(): Promise<void> {
    // check if already data for this role assigned
    const events = await Participant.queryEvents({
      page: 1,
      page_size: 1,
      start_time: this.updateLabel.start_time,
      end_time: this.updateLabel.end_time,
      role: this.updateLabel.role,
      patient: this.id,
    });
    if (events.results.length > 0) {
      // data with this role and some device already assigned to this participant
      // get device
      const deviceId = events.results?.[0].device;
      const device = await this.$apiv2.get<Device>(Device, deviceId);

      this.$buefy.dialog.confirm({
        message: this.$tc('study.dataassignment.alreadyDataRoleAssigned', 1, {
          device_name: device.name,
          device_id: device.device_id,
          role: this.updateLabel.role,
        }),
      });
      return;
    }

    // check if labels exist within time range
    const result = await DeviceEventLog.queryLabels({
      page: 1,
      page_size: 1,
      label_kind: 'patient',
      start_time: this.updateLabel.start_time,
      end_time: this.updateLabel.end_time,
      device: this.updateLabel.device,
    });
    if (result.results.length > 0) {
      // data of this device already assigned to some participant
      const [participantId, role] = result.results[0].label.split('~');
      const participant = await this.$apiv2.get<Participant>(
        Participant,
        participantId,
      );
      this.$buefy.dialog.confirm({
        message: this.$tc('study.dataassignment.alreadyDataAssigned', 1, {
          pid: participant.pid,
        }),
        onConfirm: async () => {
          const updateLabel: UpdateParticipantLabel = deepCopy(
            this.updateLabel,
          );
          updateLabel.assign = false;
          updateLabel.role = role;
          // unassign data
          await this.runAssignment(
            participantId,
            updateLabel,
            this.$tc('study.dataassignment.previousRemoved'),
          );
          // continue
          await this.newAssignment();
        },
      });
      return;
    }

    await this.runAssignment(this.id, this.updateLabel);
  }

  async runAssignment(
    participant: string,
    updateLabel: UpdateParticipantLabel,
    successMessage: string | null = this.$tc(
      'study.dataassignment.updateSuccessful',
    ),
  ): Promise<void> {
    console.debug('runAssignment');
    const loading = this.$buefy.loading.open({});
    try {
      const backgroundTask = await this.$apiv2.customPost(
        `patient/${participant}/update-device-data-assignment`,
        updateLabel,
      );
      await this.watchBgTask(backgroundTask, successMessage);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async watchBgTask(
    backgroundTask: BackgroundTask,
    successMessage: string | null,
  ): Promise<void> {
    console.debug('watchBgTask');
    return new Promise((resolve, reject) => {
      this.$bgTaskHandler.watchTask(
        backgroundTask,
        () => {
          console.debug('watchBgTask done');
          if (successMessage) {
            this.$buefy.toast.open({
              message: successMessage,
              type: 'is-success',
              duration: 5000,
              queue: false,
            });
          }
          resolve();
        },
        error => {
          reject(error);
        },
      );
    });
  }
}
