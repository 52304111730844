














































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { useDeviceAssignmentStore } from '@/devicehub/apps/dhStudy/stores/deviceAssignmentStore';
import { useRouter } from '@/devicehub/utils/router';
import { cloneDeep, isEqual } from 'lodash';
import { isEqual as isEqualDate } from 'date-fns';
import { useI18n } from '@/devicehub/utils/i18n';
import { DialogProgrammatic as Dialog } from 'buefy';
import AvailableDevicesList from './AvailableDevicesList.vue';
import { Device } from '@/generated/api/study';

interface FormValues {
  startTime: Date;
  endTime: Date | null;
  deviceUuid: string | null;
  deviceName: string | null;
}

export default defineComponent({
  components: { AvailableDevicesList },
  props: {
    participantId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const deviceAssignmentStore = useDeviceAssignmentStore();
    const router = useRouter();
    const i18n = useI18n();

    const formValues = reactive<FormValues>({
      startTime: new Date(),
      endTime: null,
      deviceUuid: null,
      deviceName: null,
    });
    let originalFormValues: FormValues | null = null;
    const isDirty = ref(false);

    const showDeviceList = ref(true);

    const isNew = computed<boolean>(() => {
      return deviceAssignmentStore.currentDeviceAssignment === null;
    });

    const showDelete = computed<boolean>(() => {
      return !isNew.value;
    });

    onMounted(() => {
      updateFormValuesWithCurrentAssignment();
      showDeviceList.value = isNew.value;
    });

    watch(formValues, () => {
      isDirty.value = !isEqual(formValues, originalFormValues);
      emit('is-dirty-changed', isDirty.value);
    });

    const updateFormValuesWithCurrentAssignment = () => {
      if (deviceAssignmentStore.currentDeviceAssignment) {
        formValues.startTime = new Date(
          deviceAssignmentStore.currentDeviceAssignment.assignment.start,
        );
        formValues.endTime = deviceAssignmentStore.currentDeviceAssignment
          .assignment.end
          ? new Date(
              deviceAssignmentStore.currentDeviceAssignment.assignment.end,
            )
          : null;
        formValues.deviceUuid =
          deviceAssignmentStore.currentDeviceAssignment.device.id;
        formValues.deviceName =
          deviceAssignmentStore.currentDeviceAssignment.device.name ?? null;
      }
      originalFormValues = cloneDeep(formValues);
    };

    const onDeviceSelected = (device: Device) => {
      formValues.deviceUuid = device.id;
      formValues.deviceName = device.name ?? null;
    };

    const canSave = computed<boolean>(() => {
      return !!formValues.deviceUuid && !!formValues.startTime;
    });

    const createAssignment = async () => {
      if (formValues.deviceUuid) {
        await deviceAssignmentStore.createDeviceAssignment(
          formValues.deviceUuid,
          props.participantId,
          formValues.startTime.toISOString(),
          formValues.endTime?.toISOString(),
        );
      }
    };

    const updateAssignment = async () => {
      if (deviceAssignmentStore.currentDeviceAssignment) {
        // update start
        const currentStart = new Date(
          deviceAssignmentStore.currentDeviceAssignment.assignment.start,
        );
        if (!isEqualDate(formValues.startTime, currentStart)) {
          await deviceAssignmentStore.updateStart(
            deviceAssignmentStore.currentDeviceAssignment,
            formValues.startTime.toISOString(),
          );
        }
        // update end
        const currentEnd = deviceAssignmentStore.currentDeviceAssignment
          .assignment.end
          ? new Date(
              deviceAssignmentStore.currentDeviceAssignment.assignment.end,
            )
          : 0;
        if (
          formValues.endTime &&
          !isEqualDate(formValues.endTime, currentEnd)
        ) {
          await deviceAssignmentStore.updateEnd(
            deviceAssignmentStore.currentDeviceAssignment,
            formValues.endTime.toISOString(),
          );
        }
        // update device
        if (
          formValues.deviceUuid &&
          formValues.deviceUuid !==
            deviceAssignmentStore.currentDeviceAssignment.device.id
        ) {
          await deviceAssignmentStore.updateDevice(
            deviceAssignmentStore.currentDeviceAssignment,
            formValues.deviceUuid,
          );
        }
      }
    };

    const onSave = async () => {
      if (isNew.value) {
        await createAssignment();
      } else {
        await updateAssignment();
      }
      emit('change-mode', 'list');
    };

    const onDelete = async () => {
      Dialog.confirm({
        message: i18n.tc('dhStudy.deviceAssignment.confirmDelete'),
        onConfirm: async () => {
          if (deviceAssignmentStore.currentDeviceAssignment) {
            await deviceAssignmentStore.deleteDeviceAssignment(
              deviceAssignmentStore.currentDeviceAssignment,
            );
          }
          emit('change-mode', 'list');
        },
      });
    };

    return {
      deviceAssignmentStore,
      router,
      formValues,
      canSave,
      showDelete,
      onSave,
      onDelete,
      isDirty,
      onDeviceSelected,
      showDeviceList,
    };
  },
});
