import { addDays, addWeeks, formatISO } from 'date-fns';

/**
 * The monitoring days are alternating weekdays per week for 12 weeks.
 * The schema is the following: MON, TUE, WED, THU, FRI, THU, WED, TUE, MO, TUE, WED, THU
 * There is one additional day in week 24.
 * @param startDate
 */
export function getMonitoringDays(startDate?: string): string[] {
  if (startDate === undefined) {
    throw new Error('Invalid start date.');
  }
  let start = new Date(startDate);
  if (isNaN(start.valueOf())) {
    throw new Error('Invalid start date.');
  }
  // if start is Saturday or Sunday, select next Monday
  if (start.getDay() === 6 || start.getDay() === 0) {
    start = addDays(start, start.getDay() === 0 ? 1 : 2);
  }

  // add first day
  const result = [formatISO(start, { representation: 'date' })];

  // add week 2 to 12
  let previsousMonitoringDay = start;
  let dayDifference = 1;
  for (let i = 0; i < 11; i++) {
    // swap the day difference if we get to Friday or back to Monday
    if (previsousMonitoringDay.getDay() === 5) {
      dayDifference = -1;
    } else if (previsousMonitoringDay.getDay() === 1) {
      dayDifference = 1;
    }
    const newMonitoringDay = addDays(previsousMonitoringDay, 7 + dayDifference);
    result.push(formatISO(newMonitoringDay, { representation: 'date' }));
    previsousMonitoringDay = newMonitoringDay;
  }

  // add week 24
  const newMonitoringDay = addWeeks(previsousMonitoringDay, 12);
  result.push(formatISO(newMonitoringDay, { representation: 'date' }));

  return result;
}
