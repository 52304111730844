/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Code provided by SNAQ
 * copied from https://www.snaq.io/dist/v1.min.js
 */
export function embedSNAQ({
  id: e,
  token: t,
  url: n,
  language: d = 'not-set',
} = {}) {
  let i = `snaq-app-${e}`,
    l = document.createElement('iframe');
  l.setAttribute('src', n),
    l.setAttribute('id', i),
    (l.style.width = '100%'),
    (l.style.height = '100%'),
    (l.style.border = '0'),
    (l.style.overflow = 'hidden');
  let s = document.getElementById(e);
  (s.innerHTML = ''),
    s.appendChild(l),
    l.addEventListener('load', function () {
      let e = document.getElementById(i).contentWindow;
      if (t) {
        let n = { snaq: { token: t } };
        'not-set' !== d && (n.snaq.language = d),
          e.postMessage(JSON.stringify(n), '*');
      }
    });
}
