import { nextTick, onMounted, Ref, ref } from '@vue/composition-api';
import { RawLocation } from 'vue-router';
import { useRoute, useRouter } from './router';

export function useTabs(numberOfTabs: number): {
  activeTab: Ref<number>;
  setTab: () => Promise<void>;
} {
  const router = useRouter();
  const route = useRoute();
  const activeTab = ref(0);

  onMounted(async () => {
    // wait for b-tabs to load
    await nextTick();
    // set active tab from route query
    const tabStr = route.query['tab'];
    if (typeof tabStr !== 'string') {
      activeTab.value = 0;
      return;
    }
    let tab = parseInt(tabStr);
    if (
      tab >= numberOfTabs ||
      tab < 0 ||
      tab === undefined ||
      tab === null ||
      isNaN(tab)
    ) {
      tab = 0;
    }
    activeTab.value = tab;
  });

  const setTab = async (): Promise<void> => {
    const location: RawLocation = {
      path: route.path,
      query: {
        ...route.query,
        tab: activeTab.value.toString(),
      },
    };
    await router.replace(location);
  };
  return { activeTab, setTab };
}
