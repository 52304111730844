















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class BasicText extends Vue {
  @Prop({ required: true }) options: { title?: string; text?: string };
}
