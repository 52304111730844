import ParticipantBaseForm from './ParticipantBaseForm.vue';
import BasicText from './BasicText.vue';
import ParticipantStateTransition from './ParticipantStateTransition.vue';
import ParticipantDeviceAssignment from './ParticipantDeviceAssignment.vue';
import ParticipantDataAssignment from './ParticipantDataAssignment.vue';
import ParticipantDataExplorer from './ParticipantDataExplorer.vue';
import ParticipantHistory from './ParticipantHistory.vue';
import CreateTileLink from '@/apps/monitoring/components/CreateTileLink.vue';
import ParticipantSettings from '@/apps/study/eulach/components/ParticipantSettings.vue';
import WellBeing from '@/apps/study/eulach/components/WellBeing.vue';
import NutritionTable from '@/apps/study/eulach/components/NutritionTable.vue';
import ActivityTable from '@/apps/study/eulach/components/ActivityTable.vue';
import HelpMessages from '@/apps/study/eulach/components/HelpMessages.vue';
import SnaqView from '@/apps/study/eulach/components/SnaqView.vue';
import { OPTION_EULACH } from '@/env';
import { VueConstructor } from 'vue/types/umd';
import { Dictionary } from '@/util/interfaces';

let participantDetailComponents: Dictionary<VueConstructor<Vue>> = {
  BasicText,
  ParticipantBaseForm,
  ParticipantStateTransition,
  ParticipantDeviceAssignment,
  ParticipantDataAssignment,
  ParticipantDataExplorer,
  ParticipantHistory,
  CreateTileLink,
};

if (OPTION_EULACH) {
  participantDetailComponents = {
    ...participantDetailComponents,
    ParticipantSettings,
    WellBeing,
    NutritionTable,
    ActivityTable,
    HelpMessages,
    SnaqView,
  };
}

export { participantDetailComponents };
