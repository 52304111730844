























































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { deepCopy } from '@/util/util';
import { Participant } from '@/models/study/models';
import { PARTICIPANT_DEFAULT } from '@/models/study/defaults';
import { ParticipantDetail, ParticipantState } from '../interfaces';
import { participantDetailComponents } from '@/apps/study/components/participant/participantDetail';
import { globalStore } from '@/store/modules/global';

@Component({
  components: {
    ...participantDetailComponents,
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeviceDetail extends Vue {
  /**
   * ID of participant
   */
  @Prop({ required: true }) id: string;

  @Watch('id')
  async onIdChanged(): Promise<void> {
    await this.init();
  }

  activeTab = 0;
  participant: Participant = deepCopy(PARTICIPANT_DEFAULT);
  participantDetail = [];
  loading = true;

  $refs: { [key: string]: any };

  shouldWarnOnLeaveFlag = false;

  runShouldWarnOnLeaveCheck(): void {
    // check if any child component has shouldWarnOnLeave === true
    this.shouldWarnOnLeaveFlag = this.participantDetail
      .map(detail => this.$refs[`${this.id}${detail.component}`])
      .some(component => {
        // any child component has shouldWarnOnLeave === true
        return (
          component?.shouldWarnOnLeave === true ||
          component?.[0]?.shouldWarnOnLeave
        );
      });
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    this.loading = true;
    const loadingComponent = this.$buefy.loading.open({});
    try {
      this.participant = await this.$apiv2.get<Participant>(
        Participant,
        this.id,
      );
      this.participantDetail = await this.getParticipantDetail();
      this.$nextTick(() => {
        // need to wait for b-tabs to load
        let tab = parseInt(this.$routerHandler.query('')['tab']);
        if (
          tab >= this.participantDetail.length ||
          tab < 0 ||
          tab === undefined ||
          tab === null ||
          isNaN(tab)
        ) {
          tab = 0;
        }
        this.activeTab = tab;
      });
    } catch (error) {
      this.handleError(error);
    }
    loadingComponent.close();
    this.loading = false;
  }

  async setTab(): Promise<void> {
    await this.$routerHandler.updateQuery('', {
      tab: this.activeTab.toString(),
    });
  }

  /**
   * Get participant detail from client app settings
   * Implemented components are:
   */
  async getParticipantDetail(): Promise<ParticipantDetail[]> {
    let participantDetailComponents: ParticipantDetail[] = [];
    const setting = globalStore.clientAppSetting('config');
    if (setting?.value) {
      const participantStates: ParticipantState[] =
        setting.value.participant_states ?? [];
      const state = participantStates.find(pState => {
        return pState.state === this.participant.state;
      });
      if (state?.participant_detail) {
        participantDetailComponents = state.participant_detail;
      }
    }
    return participantDetailComponents;
  }

  async handleStateChange(): Promise<void> {
    this.loading = true;
    this.activeTab = 0;
    await this.setTab();
    await this.init();
  }

  handleError(error: Error): void {
    this.$errorHandler.handleError(error);
  }
}
