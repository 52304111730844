
































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ConfigSelector from '@/apps/data/components/ConfigSelector.vue';
import { DeviceAssignment } from '@/models/study/models';
import { DeviceRelation } from '@/models/data/models';
import { Context, Filter } from '@/api/ApiClientV2';
import { RawLocation } from 'vue-router';
import { dataRouteName } from '@/apps/data/app';
import { BaseListColumn, ListModelField, ModelField } from '@/models/core/base';
import { globalStore } from '@/store/modules/global';
import { studyStore } from '@/store/modules/study';
import { CustomAction } from '@/components/common/interfaces';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';

@Component({
  components: {
    ConfigSelector,
  },
})
/**
 * Assign/unassign a device relation (source device) to/from a participant.
 */
export default class ParticipantDeviceAssignment extends Vue {
  // id of participant
  @Prop({ required: true }) id: string;

  mode: 'list' | 'form' = 'list';

  DeviceAssignment = DeviceAssignment;
  DeviceRelation = DeviceRelation;

  deviceAssignmentId = '0';
  deviceAssignments: DeviceAssignment[] = [];
  selectedDeviceRelation: string = null;
  unselectableRows: string[] = [];
  loadingForm = true;

  get dataClientApp(): string {
    return globalStore.clientAppSetting('data_application')?.value
      ?.data_client_app_id;
  }

  get filter(): Filter {
    return {
      patient: this.id,
    };
  }

  get columns(): BaseListColumn[] {
    const listFields: ListModelField[] = [
      {
        key: 'device_name',
      },
      {
        key: 'device_device_id',
      },
      {
        key: 'role',
      },
      {
        key: 'assigned_pid',
      },
      {
        key: 'assigned_role',
      },
    ];
    return DeviceRelation.defaultColumns(DeviceRelation.langPath, listFields);
  }

  get deviceRelationFilter(): Filter {
    return {
      application: globalStore.selection.application?.id ?? 'unknown',
      order_by: 'device_name_asc',
    };
  }

  get fields(): ModelField[] {
    return [
      {
        key: 'role',
        formFieldType: FormFieldType.SELECTION,
        formProperties: {
          class: 'width-400',
          options: this.roleOptions.map(o => {
            return {
              text: o.role,
              value: o.role,
            };
          }),
        },
      },
      {
        key: 'effective_time',
        formFieldType: FormFieldType.DATETIME_FIELD,
        formProperties: {
          class: 'width-400',
        },
      },
    ];
  }

  get roleOptions(): { role: string; template: string }[] {
    return (
      globalStore.clientAppSetting('config')?.value?.device_assignment_roles ||
      []
    );
  }

  async getDeviceAssignments(): Promise<void> {
    this.loadingForm = true;
    const loading = this.$buefy.loading.open({});
    try {
      const application =
        studyStore.dataAppSetting('data_application')?.value
          ?.data_application_id;
      const context: Context = {
        filter: { application },
        pagination: {
          page: 1,
          pageSize: 500,
        },
      };
      this.deviceAssignments = await this.$apiv2.getListItems<DeviceAssignment>(
        DeviceAssignment,
        context,
      );
      this.unselectableRows = this.deviceAssignments.map(
        d => d.device_relation,
      );
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
    this.loadingForm = false;
  }

  beforeSaveHook(deviceAssignment: DeviceAssignment): void {
    if (this.deviceAssignmentId === '0') {
      deviceAssignment.patient = this.id;
    }
    deviceAssignment.device_relation = this.selectedDeviceRelation;
  }

  add(): void {
    this.mode = 'form';
    this.getDeviceAssignments();
  }

  backToList(): void {
    this.mode = 'list';
  }

  onSelect(selection: DeviceRelation[]): void {
    this.selectedDeviceRelation = selection?.[0]?.id;
  }

  customDetail(deviceRelation: DeviceRelation): RawLocation {
    return {
      name: dataRouteName('device-relation-detail'),
      params: {
        id: deviceRelation.id,
        app_handle: studyStore.dataClientApp.handle,
      },
    };
  }

  customDetailDeviceAssignment(
    deviceAssignment: DeviceAssignment,
  ): RawLocation {
    return {
      name: dataRouteName('device-relation-detail'),
      params: {
        id: deviceAssignment.device_relation,
        app_handle: studyStore.dataClientApp.handle,
      },
    };
  }

  customActions(): CustomAction[] {
    return [
      {
        label: this.$tc('study.deviceassignment.deleteExisting'),
        icon: 'mdi-close',
        type: 'is-danger',
        disabled: (deviceRelation: DeviceRelation) => {
          // disabled when no device assignment exists
          return (
            this.deviceAssignments.findIndex(
              a => a.device_relation === deviceRelation.id,
            ) === -1
          );
        },
        callback: async (deviceRelation: DeviceRelation) => {
          this.$buefy.dialog.confirm({
            message: this.$tc('study.deviceassignment.confirmDelete'),
            onConfirm: async () => {
              const loading = this.$buefy.loading.open({});
              try {
                const deviceAssignment = this.deviceAssignments.find(
                  a => a.device_relation === deviceRelation.id,
                );
                if (deviceAssignment) {
                  await this.$apiv2.delete(
                    DeviceAssignment,
                    deviceAssignment.id,
                  );
                  await this.getDeviceAssignments();
                  this.$apiv2.refreshData();
                }
              } catch (error) {
                this.$errorHandler.handleError(error);
              }
              loading.close();
            },
          });
        },
      },
    ];
  }
}
